import Image from 'next/image';
import clsx from 'clsx';
import { useTailwindBreakpoint } from 'helpers/hooks/useTailwindBreakpoint';
import Button from 'components/02-molecules/Button';
import Heading from 'components/01-atoms/Heading';
import Icon from 'components/01-atoms/Icon';
import Container from 'components/01-atoms/Container';
import HorizontalListWithDivider from 'components/01-atoms/HorizontalListWithDivider';
import HeroOverlay from 'components/01-atoms/HeroOverlay';
import type { ReactNode } from 'react';
import TrackingLink from 'components/02-molecules/TrackingLink';
import OPlusBadges from 'components/02-molecules/OPlusBadges';

import WatchCTAButton from '../WatchCTAButton';

type Props = {
  analyticsName: string;
  buttonHref?: string;
  details?: ReactNode[];
  image: string;
  imageAltTag: string;
  infoHref?: string;
  infoTitle?: string;
  isExternalLink?: boolean;
  isIncludedWithOPlus?: boolean;
  priority?: boolean;
  subTitle?: string;
  title?: string;
  watchTitle?: string;
};

const HomepageHeroSlideItem = ({
  analyticsName,
  buttonHref,
  details = [],
  image,
  imageAltTag,
  infoHref,
  infoTitle,
  isExternalLink,
  isIncludedWithOPlus,
  priority = false,
  subTitle,
  title,
  watchTitle
}: Props) => {
  const { isScreenNarrowerThan } = useTailwindBreakpoint();
  const isMobile = isScreenNarrowerThan('narrow');

  const analytics = {
    name: analyticsName,
    placement: 'Homepage slider'
  };

  return (
    <div className="pointer-events-auto narrow:h-[624px] h-auto">
      <TrackingLink
        analytics={analytics}
        href={infoHref || buttonHref}
        title={infoTitle || `${title} Info`}
      >
        <div
          className={clsx('relative', {
            'aspect-w-16 aspect-h-9': isMobile,
            'h-full': !isMobile
          })}
        >
          <HeroOverlay />
          <div className="z-1 preview-video atomic-video">
            <Image
              alt={imageAltTag}
              className="object-cover object-center"
              fill
              priority={priority}
              src={image}
            />
          </div>
        </div>
      </TrackingLink>
      <Container classes="relative narrow-x:absolute inset-x-0 bottom-8 narrow:bottom-0 z-20 narrow-x:pr-0 ml-0 max-w-max">
        <div className="w-full narrow:mb-10">
          <OPlusBadges
            className="mb-2 narrow-x:mb-3"
            isIncludedWithOPlus={isIncludedWithOPlus}
            placement={analytics.placement}
          />
          <TrackingLink
            analytics={analytics}
            href={infoHref || buttonHref}
            title={infoTitle || `${title} Info`}
          >
            {subTitle ? (
              <Heading
                classes="max-w-2xl text-network-white narrow-x:text-left pb-2 narrow-x:pb-3"
                level={6}
                responsiveVariant="small"
                text={subTitle}
              />
            ) : null}
            <Heading
              classes="max-w-4xl text-network-white narrow-x:text-left pb-6 narrow-x:pb-8"
              level={1}
              responsiveVariant="large"
              text={title}
            />
          </TrackingLink>
          <div className="grid items-center narrow-x:flex gap-x-9 relative">
            <div className="flex items-center mb-6 narrow-x:mb-0 gap-x-7">
              <WatchCTAButton
                href={buttonHref}
                placement={analytics.placement}
                title={watchTitle || `Watch ${title}`}
              />
              {!isExternalLink && (
                <Button
                  analytics={analytics}
                  as="link"
                  href={infoHref || buttonHref}
                  icon={<Icon type="info" />}
                  size="lg"
                  text="Info"
                  title={infoTitle || `${title} Info`}
                  variant="text"
                />
              )}
            </div>
            <HorizontalListWithDivider data={details} />
          </div>
        </div>
      </Container>
    </div>
  );
};

export default HomepageHeroSlideItem;
