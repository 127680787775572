import Heading from 'components/01-atoms/Heading';
import type { WatchPlaylist } from 'helpers/types/jwplayer-subgraph';

import MediaCarousel from '../MediaCarousel';

type Props = {
  filmsAndSeriesPlaylist?: WatchPlaylist;
  shortsPlaylist?: WatchPlaylist;
};

const EditorsPicksMedia = ({
  filmsAndSeriesPlaylist,
  shortsPlaylist
}: Props) => {
  const hasFilmsAndSeriesMediaItems = Boolean(
    filmsAndSeriesPlaylist?.playlist.mediaItems.length
  );
  const hasShortsMediaItems = Boolean(
    shortsPlaylist?.playlist.mediaItems.length
  );

  if (!hasFilmsAndSeriesMediaItems && !hasShortsMediaItems) return null;

  return (
    <div>
      <Heading
        classes="text-network-white mb-6 narrow-x:mb-8"
        level={2}
        responsiveVariant="medium"
        text="Editor's Picks"
      />
      {hasFilmsAndSeriesMediaItems && (
        <MediaCarousel
          containerClasses="mb-6 narrow-x:mb-14"
          headingLevel={3}
          headingResponsiveVariant="small"
          headingText="Films & Series"
          items={filmsAndSeriesPlaylist.playlist.mediaItems}
          priority
          type="poster"
        />
      )}
      {hasShortsMediaItems && (
        <MediaCarousel
          // see: temp-trailers
          allowTrailers
          desktopViewLimit={4}
          headingLevel={3}
          headingResponsiveVariant="small"
          headingText="More to Watch"
          items={shortsPlaylist.playlist.mediaItems}
          type="thumbnailSimple"
        />
      )}
    </div>
  );
};

export default EditorsPicksMedia;
