import type { Season } from 'helpers/types/jwplayer-subgraph';

import { pluralize } from '../pluralize';

export const getSeasonsOrEpisodesLabel = (seasons: Season[]) => {
  const amountOfSeasons = seasons.length;

  if (amountOfSeasons !== 1) return pluralize(amountOfSeasons, 'Season');

  const [firstSeason] = seasons;
  const amountOfEpisodes = firstSeason.episodes.length;

  return pluralize(amountOfEpisodes, 'Episode');
};
