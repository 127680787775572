import { memo } from 'react';
import { routes } from 'lib/routes';

import Button from '../Button';

type Props = {
  category?: string;
};

const CategoryLink = memo(({ category }: Props) => {
  if (!category) return null;

  return (
    <Button
      as="link"
      color="secondary"
      href={routes.categoriesDetail(category)}
      text={category}
      variant="text"
    />
  );
});

export default CategoryLink;
