import type { ContinueWatchingResponse } from 'apis/home/types';

class ContinueWatchingAdapter {
  private value: ContinueWatchingResponse;

  constructor(rawData: ContinueWatchingResponse) {
    this.value = rawData;
  }

  adapt() {
    return this.value.videosToContinue.filter(
      (mediaItem) =>
        Boolean(mediaItem) &&
        (mediaItem.userProgress !== null || mediaItem.seriesProgress !== null)
    );
  }
}

export default ContinueWatchingAdapter;
