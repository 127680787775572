import { useQuery } from '@tanstack/react-query';
import ContinueWatchingAdapter from 'adapters/ContinueWatchingAdapter';

import { getContinueWatching } from './queryFunctions';

export const CONTINUE_WATCHING_QUERY_KEY = 'continueWatching';

export const useContinueWatching = () =>
  useQuery({
    queryFn: () => getContinueWatching(),
    queryKey: [CONTINUE_WATCHING_QUERY_KEY],
    select: (data) => new ContinueWatchingAdapter(data).adapt(),
    staleTime: 0
  });
