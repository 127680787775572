import { useContinueWatching } from 'apis/home/queries';
import MediaCarouselGate from 'components/03-organisms/MediaCarouselGate';

const ContinueWatching = () => (
  <MediaCarouselGate
    desktopViewLimit={4}
    headingText="Continue Watching"
    type="continueWatching"
    useQuery={useContinueWatching}
  />
);

export default ContinueWatching;
