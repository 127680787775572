import {
  MEDIA_ITEM_FRAGMENT,
  SERIES_PROGRESS_FRAGMENT,
  USER_PROGRESS_FRAGMENT
} from 'apis/fragments';
import { gql } from 'graphql-request';

export const GET_HOMEPAGE = gql`
  query getHomepage($id: ID) {
    editorsPicksFilmsAndSeries: watchPlaylist(playlistType: Featured) {
      playlist {
        mediaItems {
          ...MediaItemFragment
        }
      }
    }
    editorsPicksShorts: watchPlaylist(playlistType: FeaturedShorts, limit: 25) {
      playlist {
        mediaItems {
          ...MediaItemFragment
        }
      }
    }
    upcomingLiveEventsPlaylist: watchPlaylist(
      playlistType: UpcomingLiveEvents
    ) {
      playlist {
        mediaItems {
          ...MediaItemFragment
        }
      }
    }
    homepage: watchHomepage(previewId: $id) {
      id
      seo {
        title
        ogTitle
        description
        ogDescription
        ogImage
        ogImageWidth
        ogImageHeight
      }
      heroSlideshow {
        ... on EpisodeSlide {
          id
          type
          imageAltTag
          image
          optionalTitle
          episodeItem {
            ...MediaItemFragment
          }
          series {
            seriesId
            title
            description
            seasons {
              episodeCount
            }
            trailer {
              ...MediaItemFragment
            }
            seasons {
              episodes {
                mediaItem {
                  ...MediaItemFragment
                }
              }
            }
          }
        }
        ... on FilmSlide {
          id
          type
          image
          imageAltTag
          optionalTitle
          mediaItem {
            ...MediaItemFragment
          }
        }
        ... on SeriesSlide {
          id
          type
          image
          imageAltTag
          optionalTitle
          series {
            seasons {
              episodes {
                mediaItem {
                  ...MediaItemFragment
                }
              }
            }
            seriesId
            title
            trailer {
              ...MediaItemFragment
            }
          }
        }
        ... on LinkSlide {
          id
          type
          image
          imageAltTag
          postId
          imageLink
          title
        }
        ... on SeasonSlide {
          id
          type
          image
          imageAltTag
          optionalTitle
          seasonNumber
          series {
            seasons {
              episodes {
                mediaItem {
                  ...MediaItemFragment
                }
              }
              seasonNumber
              trailer {
                category
                description
                mediaId
                tags
              }
            }
            seriesId
            title
            trailer {
              ...MediaItemFragment
            }
          }
        }
      }
    }
  }
  ${MEDIA_ITEM_FRAGMENT}
`;

export const GET_CONTINUE_WATCHING = gql`
  query getContinueWatching {
    videosToContinue(limit: 10) {
      ...MediaItemFragment
      ...SeriesProgressFragment
      ...UserProgressFragment
      trailerMediaItem {
        ...TrailerMediaItemFragment
        ...UserProgressFragment
        ...SeriesProgressFragment
      }
    }
  }
  ${MEDIA_ITEM_FRAGMENT}
  ${SERIES_PROGRESS_FRAGMENT}
  ${USER_PROGRESS_FRAGMENT}
`;
