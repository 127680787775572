import { NextSeo } from 'next-seo';
import { useRouter } from 'next/router';
import { getQueryClient } from 'lib/reactQuery';
import { prefetchMarketing } from 'apis/marketing/queries';
import MediaCarousel from 'components/03-organisms/MediaCarousel';
import Container from 'components/01-atoms/Container';
import WatchContentProviders from 'components/03-organisms/WatchContentProviders';
import { parsePageSeo } from 'helpers/utils/parsePageSeo';
import { useUserType } from 'helpers/hooks/useUserType';
import { getHomepage } from 'apis/home/queryFunctions';
import type { GetStaticProps } from 'next';
import { getDehydratedState } from 'helpers/utils/getDehydratedState';
import MarketingCTA from 'components/03-organisms/MarketingCTA';
import HomepageHero from 'components/03-organisms/HomepageHero';
import Top10FilmsAndSeries from 'components/03-organisms/Top10FilmsAndSeries';
import Top10Shorts from 'components/03-organisms/Top10Shorts';
import RecommendedMedia from 'components/03-organisms/RecommendedMedia';
import type { HomepageResponse } from 'apis/home/types';
import ContinueWatching from 'components/03-organisms/ContinueWatching';
import { routes } from 'lib/routes';
import Column from 'components/01-atoms/Column';
import ErrorBoundary from 'components/03-organisms/ErrorBoundary';

import EditorsPicksMedia from '../components/03-organisms/EditorsPicksMedia';

export type Props = HomepageResponse;

const Home = ({
  editorsPicksFilmsAndSeries,
  editorsPicksShorts,
  homepage,
  upcomingLiveEventsPlaylist
}: Props) => {
  const { asPath } = useRouter();
  const { isLoggedIn, isOutsidePlusUser } = useUserType();

  return (
    <ErrorBoundary
      errorDetails={{
        location: 'Home page'
      }}
    >
      {homepage && <NextSeo {...parsePageSeo({ ...homepage.seo, asPath })} />}
      <div className="w-full h-full bg-network-black pb-16">
        {homepage && <HomepageHero data={homepage.heroSlideshow} />}
        <Container classes="pb-10 narrow-x:pb-16" overflowHidden>
          <Column>
            <EditorsPicksMedia
              filmsAndSeriesPlaylist={editorsPicksFilmsAndSeries}
              shortsPlaylist={editorsPicksShorts}
            />
            {isLoggedIn && <ContinueWatching />}
            {upcomingLiveEventsPlaylist.playlist?.mediaItems.length ? (
              <MediaCarousel
                headingText="Upcoming Live Events"
                items={upcomingLiveEventsPlaylist.playlist.mediaItems}
                type="poster"
                viewAllRoute={routes.liveEvents()}
              />
            ) : null}
            <Top10FilmsAndSeries />
            <Top10Shorts />
            {isLoggedIn && <RecommendedMedia />}
          </Column>
        </Container>

        {!isOutsidePlusUser && <MarketingCTA />}
        <WatchContentProviders />
      </div>
    </ErrorBoundary>
  );
};

export const getStaticProps: GetStaticProps = async () => {
  const queryClient = getQueryClient();
  await prefetchMarketing(queryClient);

  const props = await getHomepage();

  return {
    props: {
      ...props,
      dehydratedState: getDehydratedState(queryClient)
    },
    revalidate: 60
  };
};

export default Home;
