import Heading from 'components/01-atoms/Heading';

import RecommendedFilmsAndSeries from '../RecommendedFilmsAndSeries';
import RecommendedShorts from '../RecommendedShorts';

const RecommendedMedia = () => (
  <div>
    <Heading
      classes="text-network-white mb-6 narrow-x:mb-8"
      level={2}
      responsiveVariant="medium"
      text="Recommended"
    />
    <RecommendedFilmsAndSeries containerClasses="mb-6 narrow-x:mb-14" />
    <RecommendedShorts />
  </div>
);

export default RecommendedMedia;
