import { SeriesCountType } from 'helpers/types/jwplayer-subgraph';
import type {
  Season,
  Series,
  SeriesCount
} from 'helpers/types/jwplayer-subgraph';

export const getSeasonDetailsWithFallback = (
  season: Season,
  series: Series
) => {
  const {
    episodes,
    seasonNumber,
    trailer: { category, description, images, mediaId, productionYear, tags }
  } = season;

  const episodesProductionYears = episodes
    .map((e) => parseInt(e.mediaItem.productionYear, 10))
    .filter(Boolean);

  const seasonProductionYear = episodesProductionYears.length
    ? Math.min(...episodesProductionYears)
    : null;

  const seriesCount: SeriesCount = {
    count: episodes.length,
    type: SeriesCountType.Episodes
  };

  const seriesProgress = series.trailer.seasonsProgress?.find(
    (progress) => progress.season === seasonNumber
  );

  return {
    category: category || series.trailer.category,
    description: description || series.trailer.description,
    images: images || series.trailer.images,
    productionYear:
      productionYear || seasonProductionYear || series.trailer.productionYear,
    seriesCount: seriesCount || series.trailer.seriesCount,
    seriesProgress,
    tags: tags || series.trailer.tags,
    trailerId: mediaId || series.trailer.mediaId
  };
};
