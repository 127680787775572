import { client } from 'lib/graphqlRequest';
import isDom from 'helpers/utils/isDom';

import type { HomepageResponse, ContinueWatchingResponse } from './types';
import { GET_CONTINUE_WATCHING, GET_HOMEPAGE } from './graphql';

export const getHomepage = () => client.request<HomepageResponse>(GET_HOMEPAGE);

export const getContinueWatching = (cookies?: string) =>
  client.request<ContinueWatchingResponse>(
    GET_CONTINUE_WATCHING,
    null,
    !isDom() ? { Cookie: cookies } : null
  );
