import { HeroSlideType } from 'helpers/types/jwplayer-subgraph';

import Hero from '../Hero';
import { getSliderProps } from '../HomepageHeroSlideItem/mappings';
import HomepageHeroSlideItem from '../HomepageHeroSlideItem';
import type { GuardedHeroSlide } from '../HomepageHeroSlideItem/types';

type Props = {
  data: GuardedHeroSlide[];
};

const HomepageHero = ({ data }: Props) => {
  const filteredData = data.filter(
    (slide) =>
      slide.type !== HeroSlideType.Film ||
      (slide.type === HeroSlideType.Film && slide.mediaItem !== null)
  );

  return (
    <Hero>
      <Hero.BgContainer>
        <Hero.Slider
          slides={filteredData.map((slide, index) => {
            const sliderProps = getSliderProps(slide, index);

            return sliderProps ? (
              <HomepageHeroSlideItem
                {...getSliderProps(slide, index)}
                key={`slide-${slide.id}`}
              />
            ) : null;
          })}
        />
      </Hero.BgContainer>
    </Hero>
  );
};

export default HomepageHero;
