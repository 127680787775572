import { getFilmDetailsUrl } from 'helpers/utils/media/getFilmDetailsUrl';
import { getSeriesDetailsUrl } from 'helpers/utils/media/getSeriesDetailsUrl';
import { formatSecondsToTime } from 'helpers/utils/formatSecondsToTime';
import { getSeasonsOrEpisodesLabel } from 'helpers/utils/media/getSeasonsOrEpisodesLabel';
import { getSeriesCategory } from 'helpers/utils/media/getSeriesCategory';
import { getMediaTitle } from 'helpers/utils/media/getMediaTitle';
import { getSeriesProductionYear } from 'helpers/utils/media/getSeriesProductionYear';
import { createURL } from 'helpers/utils/url/createURL';
import CategoryLink from 'components/02-molecules/CategoryLink';
import { getEpisodeTitleWithIndex } from 'helpers/utils/media/getEpisodeTitle';
import { getOPlusInfo } from 'components/02-molecules/OPlusBadges/helpers';
import { HeroSlideType } from 'helpers/types/jwplayer-subgraph';
import type {
  EpisodeSlide,
  FilmSlide,
  LinkSlide,
  SeasonSlide,
  SeriesSlide
} from 'helpers/types/jwplayer-subgraph';
import { getSeasonDetailsWithFallback } from 'helpers/utils/getSeasonDetailsWithFallback';
import { getSeriesDuration } from 'helpers/utils/media/getSeriesDuration';

import type { GuardedHeroSlide } from './types';

const mapFilmIntoProps = (film: FilmSlide) => {
  const {
    mediaItem: {
      associatedFilmId,
      category,
      duration,
      filmDuration,
      filmId,
      filmMediaItem,
      mediaId,
      meterFlow,
      productionYear,
      title
    }
  } = film;

  const details = [
    category && <CategoryLink category={category} />,
    formatSecondsToTime(filmDuration ?? duration),
    productionYear
  ];

  const filmUrl = getFilmDetailsUrl(
    film.optionalTitle ?? title,
    mediaId || associatedFilmId
  );

  const { isIncludedWithOPlus } = getOPlusInfo(
    filmMediaItem ? filmMediaItem.meterFlow : meterFlow
  );

  return {
    analyticsName: 'Films detail page link',
    buttonHref: createURL(filmUrl, { mode: 'fullscreen' }),
    details,
    infoHref: filmUrl,
    isIncludedWithOPlus,
    title: getMediaTitle(film.optionalTitle ?? title),
    userProgressMediaId: filmId
  };
};

const mapSeriesIntoProps = (series: SeriesSlide) => {
  const {
    series: { seasons, seriesId, trailer }
  } = series;

  const seriesTitle = getMediaTitle(trailer.title);

  const category = getSeriesCategory(trailer, seasons);

  const details = [
    category && <CategoryLink category={category} />,
    getSeasonsOrEpisodesLabel(seasons),
    getSeriesProductionYear(seasons)
  ];

  const seriesUrl = getSeriesDetailsUrl(seriesTitle, seriesId);

  const { isIncludedWithOPlus } = getOPlusInfo(trailer.seriesMeterflow);

  return {
    analyticsName: 'Series detail page link',
    buttonHref: createURL(seriesUrl, { mode: 'fullscreen' }),
    details,
    infoHref: seriesUrl,
    infoTitle: `${seriesTitle} Info`,
    isIncludedWithOPlus,
    isSeries: true,
    title: series.optionalTitle ?? seriesTitle,
    userProgressMediaId: trailer.mediaId,
    watchTitle: `Watch ${seriesTitle}`
  };
};

const mapLinkIntoProps = (link: LinkSlide) => {
  const { imageLink, title } = link;

  return {
    analyticsName: 'External link',
    buttonHref: imageLink,
    isExternalLink: true,
    title
  };
};

const mapEpisodeIntoProps = (episode: EpisodeSlide) => {
  const {
    episodeItem: { category, episodeMeta, productionYear },
    series: { seasons, seriesId, trailer }
  } = episode;

  const seriesTitle = getMediaTitle(trailer.title);

  const seriesUrl = getSeriesDetailsUrl(seriesTitle, seriesId);

  const infoHref = createURL(
    seriesUrl,
    episodeMeta
      ? {
          episode: `${episodeMeta.episode}`,
          season: `${episodeMeta.season}`
        }
      : null
  );

  const buttonHref = createURL(
    seriesUrl,
    episodeMeta
      ? {
          episode: `${episodeMeta.episode}`,
          mode: 'fullscreen',
          season: `${episodeMeta.season}`
        }
      : null
  );

  const details = [
    category && <CategoryLink category={category} />,
    getSeasonsOrEpisodesLabel(seasons),
    getSeriesProductionYear(seasons) ?? productionYear
  ];

  // Display in second iteration
  // const { isIncludedWithOPlus } = getOPlusInfo(trailer.seriesMeterflow);

  return {
    ...episode,
    // Display in second iteration
    // isIncludedWithOPlus,
    analyticsName: 'Series detail page link',
    buttonHref,
    details,
    infoHref,
    infoTitle: `${seriesTitle} Info`,
    isSeries: true,
    subTitle: seriesTitle,
    title:
      episode.optionalTitle ??
      getEpisodeTitleWithIndex(episodeMeta.episode, episode.episodeItem.title),
    userProgressMediaId: trailer.mediaId,
    watchTitle: `Watch ${seriesTitle}`
  };
};

const mapSeasonIntoProps = (season: SeasonSlide) => {
  const { seasonNumber, series } = season;

  const currentSeason = series.seasons.find(
    (s) => s.seasonNumber === seasonNumber
  );

  const { productionYear, seriesCount } = getSeasonDetailsWithFallback(
    currentSeason,
    series
  );

  const seriesTitle = getMediaTitle(series.trailer.title);

  const category = getSeriesCategory(currentSeason, series.seasons);

  const details = [
    category && <CategoryLink category={category} />,
    getSeriesDuration(seriesCount),
    productionYear
  ];

  const seriesUrl = createURL(
    getSeriesDetailsUrl(seriesTitle, series.seriesId),
    {
      season: seasonNumber.toString()
    }
  );

  return {
    analyticsName: 'Season detail page link',
    buttonHref: createURL(seriesUrl, { mode: 'fullscreen' }),
    details,
    infoHref: seriesUrl,
    infoTitle: `${seriesTitle} Season ${seasonNumber} Info`,
    subTitle: seriesTitle,
    title: season.optionalTitle ?? `Season ${seasonNumber}`,
    userProgressMediaId: series.trailer.mediaId,
    watchTitle: `Watch ${seriesTitle} Season ${seasonNumber}`
  };
};

export const getSliderProps = (slide: GuardedHeroSlide, index: number) => {
  const { id, image, imageAltTag } = slide;

  const commonProps = {
    id,
    image,
    imageAltTag,
    priority: index === 0
  };

  switch (slide.type) {
    case HeroSlideType.Film:
      return {
        ...commonProps,
        ...mapFilmIntoProps(slide)
      };
    case HeroSlideType.Series:
      return {
        ...commonProps,
        ...mapSeriesIntoProps(slide)
      };
    case HeroSlideType.Link:
      return {
        ...commonProps,
        ...mapLinkIntoProps(slide)
      };
    case HeroSlideType.Episode:
      return {
        ...commonProps,
        ...mapEpisodeIntoProps(slide)
      };
    case HeroSlideType.Season:
      return {
        ...commonProps,
        ...mapSeasonIntoProps(slide)
      };
    default:
      return null;
  }
};
